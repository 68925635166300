import React, { useContext } from "react";
import sitelogo from "../../assets/Images/svg/mainlogo.svg";
import closeimg from "../../assets/Images/svg/closeimg.svg";
import hamburimg from "../../assets/Images/svg/headerhambur.svg";
import avatarimg from "../../assets/Images/svg/avatarimg.svg";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/Context";
import { useCookies } from "react-cookie";
const Header = () => {
  const { opensidebar, setOpenSidebar, userabout, setUserAbout } =
    useContext(Context);
  const navigate = useNavigate();
  const [cookies] = useCookies(["token"]);
  return (
    <header>
      <section className="header__section">
        <div className="hader__wrapper">
          <div className="header__mobile__logo">
            <button
              onClick={() => setOpenSidebar(!opensidebar)}
              className="header__control__btn"
            >
              <img src={opensidebar ? closeimg : hamburimg} alt="" />
            </button>
            <img
              width={93}
              onClick={() => navigate("/")}
              className="header__sitelogo"
              src={sitelogo}
              alt=""
            />
          </div>
          {cookies.token && (
            <div className="header__userprofile">
              <div className="header__avatar__wrapper">
                <div
                  onClick={() => navigate("/profile")}
                  className="header__avatarbox"
                >
                  <img className="header__avatar__img" src={avatarimg} alt="" />
                </div>
              </div>
              <div className="header__userabout">
                <h5 className="header__username">
                  {userabout?.username?.length > 15
                    ? `${userabout?.username?.slice(0, 15)}...`
                    : userabout?.username}
                </h5>
                <h6 className="header__limit">
                  Limit: {userabout?.limit?.total}
                </h6>
              </div>
            </div>
          )}
        </div>
      </section>
    </header>
  );
};

export default Header;
