import React from "react";
import "./logout.css";
import { useNavigate } from "react-router-dom";
import http from "../../service/axios";
import { useCookies } from "react-cookie";
const Logout = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const handleLogout = () => {
    http
      .post("/profile/logout/")
      .then((res) => {
        if (res.status === 200) {
          removeCookie("token", { path: "/" });
          navigate("/");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="logout">
      <div className="logout__wrapper">
        <h2 className="logout__title">You are logged in</h2>
        <button onClick={() => handleLogout()} className="logout__btn">
          Logout
        </button>
      </div>
    </div>
  );
};

export default Logout;
