import React, { useEffect } from "react";
import Layout from "./pages/Layout/Layout";
import { Routes, Route } from "react-router-dom";
import Market from "./pages/Market/Market";
import Rules from "./pages/Rules/Rules";
import Orders from "./pages/Orders/Orders";
import Profile from "./pages/Profile/Profile";
import History from "./pages/History/History";
import Login from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";
import Notfound from "./pages/NotFound/Notfound";
import Productitem from "./pages/Productitem/Productitem";
import Loading from "./pages/Loading/Loading";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import Category from "./pages/Category/Category";
const App = () => {
  const [cookies] = useCookies(["token"]);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Category />} />
          <Route path="/category/item/:id" element={<Market />} />
          <Route path="/rules" element={<Rules />} />
          {cookies.token && (
            <>
              <Route path="/orders" element={<Orders />} />
              <Route path="/history-order" element={<History />} />
              <Route path="/profile" element={<Profile />} />
            </>
          )}
          <Route path="/accounts/callback" element={<Loading />} />
          {cookies.token ? (
            <Route path="/logout" element={<Logout />} />
          ) : (
            <Route path="/login" element={<Login />} />
          )}
          <Route path="/login" element={<Login />} />

          <Route path="/product/:id" element={<Productitem />} />
          <Route path="/accounts/callback" element={<Loading />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Routes>
    </div>
  );
};

export default App;
