import React, { useState } from "react";
import "./checkbox.css";
import checkedimg from "../../assets/Images/svg/checked.svg";
const Checkbox = ({ handleChange, array, id }) => {
  const [isChecked, setIsChecked] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
    handleChange();
  };

  return (
    <div>
      <div className="custom-checkbox" onClick={toggleCheckbox}>
        <input
          type="checkbox"
          className="hidden-checkbox"
          checked={isChecked}
        />
        <div className={`checkbox ${array?.includes(id) ? "checked" : ""}`}>
          {array?.includes(id) && (
            <img src={checkedimg} alt="" className="checkedimg" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Checkbox;

export const Simplecheckbox = ({ type, toggleCheckbox }) => {
  return (
    <div>
      <div className="custom-checkbox" onClick={toggleCheckbox}>
        <input type="checkbox" className="hidden-checkbox" checked={type} />
        <div className={`checkbox ${type ? "checked" : ""}`}>
          {type && <img src={checkedimg} alt="" className="checkedimg" />}
        </div>
      </div>
    </div>
  );
};
