import React from "react";
import "./preloader.css";
const Preloader = () => {
  return (
    <div className="preloader">
      <span className="preloader__circle"></span>
    </div>
  );
};

export default Preloader;
