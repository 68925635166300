import React, { useContext } from "react";
import "./profile.css";
import avatartimg from "../../assets/Images/svg/avatarimg.svg";
import { Context } from "../../context/Context";
const Profile = () => {
  const { userabout } = useContext(Context);

  return (
    <section className="profile__section">
      <div className="profile__head">
        <div>
          <div className="profile__profilebox">
            <img className="profile__profileimg" src={avatartimg} alt="" />
          </div>
        </div>
        <div className="profile__profileinfo">
          <h3 className="profile__name">
            {userabout?.first_name} {userabout?.last_name}
          </h3>
          <h6 className="profile__limit__about">
            Limit: {userabout?.limit?.total}
          </h6>
        </div>
      </div>
      <div className="profile__limit">
        <h3 className="profile__limit__title">Limits</h3>
        <div className="profile__limit__wrapper">
          <ul className="profile__limit__list">
            {userabout?.limit?.limits
              ?.slice(0, Math.ceil(userabout?.limit?.limits?.length / 2))
              ?.map((item, index) => (
                <li key={index} className="profile__limit__listitem">
                  <h4 className="profile__limit__about__listname">
                    {item?.title}
                  </h4>
                  <div className="profile__limit__about__border">
                    ..............................................................................................................................................................................................
                    ..............................................................................................................................................................................................
                  </div>
                  <p className="profile__limit__about__listvalue">
                    {item?.limit}
                  </p>
                </li>
              ))}
          </ul>
          <ul className="profile__limit__list">
            {userabout?.limit?.limits
              ?.slice(
                Math.ceil(userabout?.limit?.limits?.length / 2),
                userabout?.limit?.length
              )
              ?.map((item, index) => (
                <li key={index} className="profile__limit__listitem">
                  <h4 className="profile__limit__about__listname">
                    {item?.title}
                  </h4>
                  <div className="profile__limit__about__border">
                    ..............................................................................................................................................................................................
                    ..............................................................................................................................................................................................
                  </div>
                  <p className="profile__limit__about__listvalue">
                    {item?.limit}
                  </p>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <ul className="profile__list">
        <li className="profile__listitem">
          <h4 className="profile__listname">First Name</h4>
          <p className="profile__listvalue">{userabout?.first_name}</p>
        </li>
        <li className="profile__listitem">
          <h4 className="profile__listname">Last Name</h4>
          <p className="profile__listvalue">{userabout?.last_name}</p>
        </li>
        <li className="profile__listitem">
          <h4 className="profile__listname">Username</h4>
          <p className="profile__listvalue">{userabout?.username}</p>
        </li>
        <li className="profile__listitem">
          <h4 className="profile__listname">E-mail</h4>
          <p className="profile__listvalue">{userabout?.email}</p>
        </li>
      </ul>
    </section>
  );
};

export default Profile;
