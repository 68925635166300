import React from "react";
import "./login.css";
const Login = () => {
  return (
    <div className="login">
      <div className="login__wrapper">
        <h2 className="login__title"> Sign In Via Okta</h2>
        <a
          href={`${process.env.REACT_APP_MAINURL}/oauth2/v1/authorize?client_id=${process.env.REACT_APP_CLIENTID}&response_type=code&scope=openid+profile+email&redirect_uri=${process.env.REACT_APP_REDIRECTURL}`}
          className={"login__btn"}
        >
          Sign In
        </a>
      </div>
    </div>
  );
};

export default Login;
