import React, { useContext } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import "./layout.css";
import { Context } from "../../context/Context";
import { Outlet } from "react-router-dom";
import closesureimg from "../../assets/Images/svg/closesure.svg";
import Modal from "../../components/Modal/Modal";
const Layout = () => {
  const { opensidebar, descsidebar, authormodal, handleLogout } =
    useContext(Context);
  return (
    <div className="layout">
      <Modal isOpen={authormodal} onClose={handleLogout}>
        <div className="deletesure">
          <div className="deletesure__wrapper">
            <button onClick={() => handleLogout()} className="deletesure__btn">
              <img src={closesureimg} alt="" />
            </button>
            <h4 className="deletesure__title">
              Your session has expired. Please log in again.
            </h4>

            <button onClick={() => handleLogout()} className="itemoder__btn">
              OK
            </button>
          </div>
        </div>
      </Modal>
      <div
        className={
          opensidebar
            ? "layout__sidebar layout__sidebar__open"
            : "layout__sidebar"
        }
      >
        <Sidebar />
      </div>
      <div
        className={
          opensidebar
            ? ` ${
                descsidebar ? "layout__main__open" : "layout__main"
              } layout__main__open`
            : `${descsidebar ? "layout__main__open" : "layout__main"}`
        }
      >
        <Header />
        <div className="layout__outlet">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
