import React, { useContext, useEffect, useState } from "react";
import correctimg from "../../assets/Images/svg/correct.svg";
import sortarrowimg from "../../assets/Images/svg/sortarrow.svg";
import deleteimg from "../../assets/Images/svg/delete.svg";
import closemodalimg from "../../assets/Images/svg/closemodal.svg";
import closesureimg from "../../assets/Images/svg/closesure.svg";
import "./order.css";
import Modal from "../../components/Modal/Modal";
import http from "../../service/axios";
import Preloader from "../../components/Preloader/Preloader";
import { Context } from "../../context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Countiteme from "../History/CounterDownTimer";
const Orders = () => {
  // Order get -- /
  const {
    handleReresh,
    itemaddmodal,
    closeAddmodal,
    setAutorModal,
    infotext,
    orderModal,
  } = useContext(Context);
  const [loadin, setLoadin] = useState(false);
  const [oder, setOrder] = useState([]);
  const [modal, setModal] = useState(false);
  const [orderrefresh, setOrderrefresh] = useState(false);
  const [modalcontent, setModalcontent] = useState("Inexpensive first");
  const [suremodal, setSuremodal] = useState(false);
  const [removeText, setRemovetext] = useState("");
  const [removeid, SetRemoveid] = useState("");
  const getFormtext = () => {
    http
      .get("/api/v1/form/remove")
      .then((res) => {
        setRemovetext(res?.data?.at(0)?.text_remove);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getFormtext();
  }, []);
  const openSuremodal = () => {
    setSuremodal(true);
  };
  const closeSuremodal = () => {
    setSuremodal(false);
  };
  const getOrder = () => {
    setLoadin(true);
    let soryby = "price";
    if (modalcontent === "Inexpensive first") {
      soryby = "price";
    } else if (modalcontent === "Expensive first") {
      soryby = "-price";
    } else if (modalcontent === "A-Z") {
      soryby = "product__title ";
    } else if (modalcontent === "Z-A") {
      soryby = "-product__title ";
    }
    http
      .get(`/api/v1/booking/list/?ordering=${soryby}`)
      .then((res) => {
        setLoadin(false);
        setOrder(res.data);
      })
      .catch((err) => {
        setLoadin(false);
        console.log(err);
        if (err.response.status === 401) {
          setAutorModal(true);
        }
      });
  };
  useEffect(() => {
    getOrder();
  }, [modalcontent, orderrefresh]);
  // ---- Modal sort ---
  const handleCheck = (item) => {
    setModalcontent(item);
    closeModal();
  };
  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };
  const handleRemove = () => {
    http
      .delete(`/api/v1/order/delete/${removeid}/`)
      .then((res) => {
        setOrderrefresh(!orderrefresh);
        handleReresh();
        SetRemoveid("");
        closeSuremodal();
        toast.warn(
          "The item has been successfully removed and returned back to the catalog.",
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          }
        );
        setTimeout(() => {
          setOrderrefresh(!orderrefresh);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <section className="orders">
      <ToastContainer />
      {loadin && <Preloader />}
      <Modal isOpen={modal} onClose={closeModal}>
        <div className="modal__sortby">
          <h4 className="modal__sortby__title">Sort by:</h4>
          <ul className="modal__sortby__list">
            <li className="modal__sortby__listitem">
              <h5 className="modal__sortby__item">Inexpensive first</h5>
              <div
                onClick={() => handleCheck("Inexpensive first")}
                className="modal__sortby__radio"
              >
                {modalcontent === "Inexpensive first" ? (
                  <div className="modal__sortby__radio__item"></div>
                ) : (
                  ""
                )}
              </div>
            </li>
            <li className="modal__sortby__listitem">
              <h5 className="modal__sortby__item">Expensive first</h5>
              <div
                onClick={() => handleCheck("Expensive first")}
                className="modal__sortby__radio"
              >
                {modalcontent === "Expensive first" ? (
                  <div className="modal__sortby__radio__item"></div>
                ) : (
                  ""
                )}
              </div>
            </li>
            <li className="modal__sortby__listitem">
              <h5 className="modal__sortby__item">A-Z</h5>
              <div
                onClick={() => handleCheck("A-Z")}
                className="modal__sortby__radio"
              >
                {modalcontent === "A-Z" ? (
                  <div className="modal__sortby__radio__item"></div>
                ) : (
                  ""
                )}
              </div>
            </li>
            <li className="modal__sortby__listitem">
              <h5 className="modal__sortby__item">Z-A</h5>
              <div
                onClick={() => handleCheck("Z-A")}
                className="modal__sortby__radio"
              >
                {modalcontent === "Z-A" ? (
                  <div className="modal__sortby__radio__item"></div>
                ) : (
                  ""
                )}
              </div>
            </li>
          </ul>
          <button onClick={() => closeModal()} className="modal__closebtn">
            <img src={closemodalimg} alt="" />
          </button>
        </div>
      </Modal>
      <Modal isOpen={suremodal} onClose={closeSuremodal}>
        <div className="deletesure">
          <div className="deletesure__wrapper">
            <button
              onClick={() => closeSuremodal()}
              className="deletesure__btn"
            >
              <img src={closesureimg} alt="" />
            </button>
            <h4
              className="deletesure__title"
              dangerouslySetInnerHTML={{ __html: removeText }}
            ></h4>
            <div className="deletesure__btns">
              <button
                onClick={() => handleRemove()}
                className="deletesure__btnyes"
              >
                Yes
              </button>
              <button
                onClick={() => {
                  SetRemoveid("");
                  closeSuremodal();
                }}
                className="deletesure__btnno"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal isOpen={itemaddmodal} onClose={closeAddmodal}>
        <div className="deletesure">
          <div className="deletesure__wrapper">
            <button onClick={() => closeAddmodal()} className="deletesure__btn">
              <img src={closesureimg} alt="" />
            </button>
            <h4 className="deletesure__title">Item added to Orders</h4>
            <div
              className="itemorder__info"
              dangerouslySetInnerHTML={{
                __html: infotext,
              }}
            ></div>
            <button onClick={() => closeAddmodal()} className="itemoder__btn">
              OK
            </button>
          </div>
        </div>
      </Modal>

      <h2 className="orders__title">Order list</h2>
      <div className="orders__sort__wrapper">
        <div className="orders__sort__box">
          <h4 className="orders__sort__text">Sort by:</h4>
          <h5 onClick={() => openModal()} className="orders__sord__title">
            {modalcontent} <img src={sortarrowimg} alt="" />
          </h5>
        </div>
      </div>
      <ul className="orders__list">
        {oder?.map((item, index) => (
          <div className="orders__listitemmain">
            <li key={index} className="orders__listitem">
              <div>
                <div
                  style={{
                    backgroundImage: `url(${
                      item?.product?.images?.at(0)?.image
                    })`,
                  }}
                  className="orders__product__img__box"
                >
                  {item?.discount_price ? (
                    <div className="orders__product__sale">
                      -{item?.product.discount_price}%
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="orders__pruduct__information__wrapper">
                <h4 className="orders__product__title">
                  {item?.product.title}
                </h4>
                <div className="orders__product__wrapper">
                  <div className="orders__product__mainbox">
                    <div className="orders__product__firstabout">
                      <div className="orders__product__aboutinner">
                        <h5 className="orders__product__aboutinner__title">
                          Name
                        </h5>
                        <div className="orders__bottom__border">
                          ....................................................................................................
                        </div>
                        <h6 className="orders__product__aboutinner__info">
                          {item?.product.title}
                        </h6>
                      </div>
                      <div className="orders__product__aboutinner">
                        <h5 className="orders__product__aboutinner__title">
                          Qty
                        </h5>
                        <div className="orders__bottom__border">
                          ....................................................................................................
                        </div>
                        <h6 className="orders__product__aboutinner__info">
                          {item?.count}
                        </h6>
                      </div>
                      {item?.product?.discount_price ? (
                        <div className="orders__product__aboutinner">
                          <h5 className="orders__product__aboutinner__title">
                            Discount price
                          </h5>
                          <div className="orders__bottom__border">
                            ....................................................................................................
                          </div>
                          <h6 className="orders__product__aboutinner__info">
                            {item?.product?.discount_price}%
                          </h6>
                        </div>
                      ) : (
                        " "
                      )}

                      {item?.product?.info
                        ?.slice(
                          0,
                          Math.ceil(item?.product.info?.length / 2) - 1
                        )
                        ?.map((item, index) => (
                          <div
                            key={index}
                            className="orders__product__aboutinner"
                          >
                            <h5 className="orders__product__aboutinner__title">
                              {item?.key}
                            </h5>
                            <div className="orders__bottom__border">
                              ....................................................................................................
                            </div>
                            <h6 className="orders__product__aboutinner__info">
                              {item?.value}
                            </h6>
                          </div>
                        ))}
                    </div>
                    <div className="orders__product__firstabout">
                      {item?.product.info
                        ?.slice(
                          Math.floor(item?.product.info?.length / 2),
                          item?.product?.info?.length
                        )
                        ?.map((item, index) => (
                          <div
                            key={index}
                            className="orders__product__aboutinner"
                          >
                            <h5 className="orders__product__aboutinner__title">
                              {item?.key}
                            </h5>
                            <div className="orders__bottom__border">
                              ....................................................................................................
                            </div>
                            <h6 className="orders__product__aboutinner__info">
                              {item?.value}
                            </h6>
                          </div>
                        ))}
                      <div className="orders__product__aboutinner">
                        <h5 className="orders__product__aboutinner__title">
                          Condition
                        </h5>
                        <div className="orders__bottom__border">
                          ....................................................................................................
                        </div>

                        <p
                          style={{
                            color: `${item?.product?.color} `,
                          }}
                          className="market__product__aboutlist__item__text "
                        >
                          {item?.product?.condition?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <button className="orders__product__correct__btn">
                  <img src={correctimg} alt="" />
                {item?.price} {item?.currency_title}
                </button> */}
                  <div className="orders__plan__statusbox">
                    <div className="orders__bottom__border__item">
                      <h4 className="orders__bottom__border__status">
                        <span className="oders__bottom__boder__statusbold">
                          Status:{" "}
                        </span>
                        {item?.status === "under" ? "Under consideration" : ""}
                        {item?.status === "reserved" ? "Reserved" : ""}
                        {item?.status === "purchased" ? "Purchased" : ""}
                        {item?.status === "cancelled" ? "Cancelled" : ""}
                      </h4>
                      <h4 className="orders__bottom__border__status">
                        <span className="oders__bottom__boder__statusbold">
                          Expired time:{" "}
                        </span>
                        {item?.status === "under" ? (
                          <Countiteme data={`${item.created_at}`} />
                        ) : (
                          "-"
                        )}
                      </h4>
                    </div>
                  </div>
                  <div className="orders__product__buttons__wrapper">
                    {item?.status === "reserved" || item?.status === "under" ? (
                      <button
                        onClick={() => {
                          SetRemoveid(item?.id);
                          openSuremodal();
                        }}
                        className="orders__product__deleteimg"
                      >
                        <img
                          className={"orders__product__deleteimg__img"}
                          src={deleteimg}
                          alt=""
                        />
                      </button>
                    ) : (
                      ""
                    )}
                    <button className="orders__product__simplebtn">
                      {item?.product?.price * item?.count}
                      {item?.product?.currency_title}
                    </button>
                  </div>
                </div>
              </div>
            </li>

            <div className="orders__listbottom__border">
              <div className="orders__bottom__border__item">
                <h4 className="orders__bottom__border__status">
                  <span className="oders__bottom__boder__statusbold">
                    Status:{" "}
                  </span>
                  {item?.status === "under" ? "Under consideration" : ""}
                  {item?.status === "reserved" ? "Reserved" : ""}
                  {item?.status === "purchased" ? "Purchased" : ""}
                  {item?.status === "cancelled" ? "Cancelled" : ""}
                </h4>
                <h4 className="orders__bottom__border__status">
                  <span className="oders__bottom__boder__statusbold">
                    Expired time:{" "}
                  </span>
                  {item?.status === "under" ? (
                    <Countiteme data={`${item.created_at}`} />
                  ) : (
                    "-"
                  )}
                </h4>
              </div>
            </div>
          </div>
        ))}
      </ul>
    </section>
  );
};

export default Orders;
