import React, { useContext, useEffect, useRef, useState } from "react";
import previmg from "../../assets/Images/svg/prevnavigate.svg";
import ctrolprevimg from "../../assets/Images/svg/ctrolprev.svg";
import ctrolnextimg from "../../assets/Images/svg/ctrolnext.svg";
import closeimg from "../../assets/Images/svg/closeimg.svg";
import plusimg from "../../assets/Images/svg/plus.svg";
import minusimg from "../../assets/Images/svg/minus.svg";
import "./productitem.css";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import http from "../../service/axios";
import { useCookies } from "react-cookie";
import { Context } from "../../context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Modal from "../../components/Modal/Modal";
const Productitem = () => {
  const {
    handleReresh,
    openAddmodal,
    setAutorModal,
    setInfotext,
    setOrderModal,
  } = useContext(Context);
  const navigate = useNavigate();
  const [cookies] = useCookies(["token"]);
  const { id } = useParams();
  const [acitveimg, setActiveimg] = useState(0);
  const [ordernum, setOrdernum] = useState(1);
  const [modalcount, setModalCount] = useState(false);
  //  ---- getdata -- /
  const [data, setData] = useState({});
  const closeModalcount = () => {
    setModalCount(false);
    setOrdernum(1);
  };
  const getData = () => {
    http
      .get(`/api/v1/product/detail/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          setAutorModal(true);
        }
      });
  };
  useEffect(() => {
    getData();
  }, []);

  // ---- mobile carusel ----
  const swiperRef = useRef(null);
  const pagination = {
    el: ".swiper-custom-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + " " + "</span>";
    },
  };

  const handleOrder = (id) => {
    if (cookies.token) {
      http
        .get(`/api/v1/form/${id}/`)
        .then((res) => {
          setInfotext(res.data?.at(0)?.text_info);
        })
        .catch((err) => {
          console.log(err);
        });

      http
        .post("/api/v1/order/create/", {
          product: id - 0,
          status: "under",
          count: ordernum,
        })
        .then((res) => {
          handleReresh();
          setOrderModal(res.data?.pop_up);
          toast.success("Item successfully booked", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setTimeout(() => {
            openAddmodal();
            navigate("/orders");
          }, 2500);
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            toast.error(
              "You have run out of available limits in this category for this calendar year ",
              {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              }
            );
          }
        });
    } else {
      navigate("/login");
    }
  };
  const handleNext = () => {
    if (acitveimg < data.images.length - 1) {
      setActiveimg(acitveimg + 1);
    } else {
      setActiveimg(0);
    }
  };
  const handlePrev = () => {
    if (acitveimg > 0) {
      setActiveimg(acitveimg - 1);
    } else {
      setActiveimg(data.images.length - 1);
    }
  };
  return (
    <section className="productitem">
      <ToastContainer />
      <Modal isOpen={modalcount} onClose={closeModalcount}>
        <div className="modalcout">
          <button
            onClick={() => closeModalcount()}
            className="modalcount__closebtn"
          >
            <img src={closeimg} alt="closeimg" />
          </button>
          <div className="modalcount__box">
            <h3 className="modalcount__title">
              How many items would you like to order?
            </h3>
            <div className="modalcount__wrapper">
              <div className="modalcount__imgbox">
                <img
                  className="modalcount__img"
                  src={data?.images?.at(0)?.image}
                  alt="orderimg"
                />
              </div>
              <div className="modalcount__infobox">
                <h4 className="modalcount__leftnum">
                  Items left: {data?.count}
                </h4>
                <div className="modalcount__countbox">
                  <div className="modalcount__countwrapper">
                    <button
                      onClick={() => {
                        if (ordernum > 1) {
                          setOrdernum((prev) => prev - 1);
                        }
                      }}
                      className="modalcount__controlbtn"
                    >
                      <img src={minusimg} alt="minusimg" />
                    </button>
                    <span className="modalcount__orderednum">{ordernum}</span>
                    <button
                      onClick={() => {
                        if (data?.count !== ordernum) {
                          setOrdernum((prev) => prev + 1);
                        }
                      }}
                      className="modalcount__controlbtn"
                    >
                      <img src={plusimg} alt="plusimg" />
                    </button>
                  </div>
                  <h5 className="modalcount__price">
                    {data?.price * ordernum} {data?.currency_title}
                  </h5>
                </div>
                <button
                  onClick={() => handleOrder(data?.id)}
                  className="modalcount__orderbtn"
                >
                  Add Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="productitem__header__wrapper">
        <div>
          <div onClick={() => navigate(-1)} className="product__prev__navigate">
            <img src={previmg} alt="" />
          </div>
          <div onClick={() => navigate(-1)} className="product__prev__mobile">
            <img className="product__mobile__previmg" src={previmg} alt="" />
            <h4 className="product__prev__mobile__title">Back</h4>
          </div>
        </div>
        <h2 className="productitem__title">{data?.title}</h2>
      </div>
      <div className="productitem__wrapper">
        {data?.limit === "no_limit" ? (
          <span className="productitem__unlimit2">Unlimited</span>
        ) : (
          ""
        )}
        <div className="productitem__innerwrapper">
          <div>
            <ul className="productitem__imgs__list">
              {data?.images?.map((item, index) => (
                <li
                  onClick={() => setActiveimg(index)}
                  key={index}
                  style={
                    acitveimg === index
                      ? { backgroundImage: `url(${item?.image})` }
                      : {
                          background: `linear-gradient(45.00deg, rgba(255, 255, 255, 0.5) 100%,rgba(255, 255, 255, 0.3) 100%),url(${item?.image})`,
                        }
                  }
                  className="productitem__imgs__listitem"
                >
                  {" "}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div
              style={{
                background: `url(${data?.images?.at(acitveimg)?.image})`,
              }}
              className="productitem__mainimg"
            >
              {data?.images?.length > 0 ? (
                <div className="productitem__mainimgs__ctroll">
                  <button
                    onClick={() => handlePrev()}
                    className="producitem__mianims__btn"
                  >
                    <img src={ctrolprevimg} alt="" />
                  </button>
                  <div className="productitem__mainimgs__text">
                    <h5>
                      {acitveimg + 1} / {data?.images?.length}
                    </h5>
                  </div>
                  <button
                    onClick={() => handleNext()}
                    className="producitem__mianims__btn"
                  >
                    <img src={ctrolnextimg} alt="" />
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="productitem__mobilewrapper">
          <Swiper
            pagination={pagination}
            modules={[Pagination]}
            className="productitem__swiper"
            spaceBetween={24}
            loop
            breakpoints={{
              300: { slidesPerView: 1 },
              630: { slidesPerView: 1 },
              1260: { slidesPerView: 1 },
            }}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {data?.images?.map((item, index) => (
              <SwiperSlide
                key={index}
                style={{
                  background: `url(${item?.image})`,
                }}
                className="product__carusel__item"
              ></SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="swiper-custom-pagination" />
        <div className="producitem__maininfo">
          {data?.limit === "no_limit" ? (
            <span className="productitem__unlimit">Unlimited</span>
          ) : (
            ""
          )}

          <div className="productitem__info">
            <div>
              <ul className="productitem__about__list">
                <li className="productitem__about__listitem">
                  <h4 className="productitem__about__listname">Name</h4>
                  <div className="productitem__about__border">
                    ..............................................................................................................................................................................................
                  </div>
                  <p className="productitem__about__listvalue">{data?.title}</p>
                </li>
                <li className="productitem__about__listitem">
                  <h4 className="productitem__about__listname">Qty</h4>
                  <div className="productitem__about__border">
                    ..............................................................................................................................................................................................
                  </div>
                  <p className="productitem__about__listvalue">{data?.count}</p>
                </li>
                {data?.is_car ? (
                  <li className="productitem__about__listitem">
                    <h4 className="productitem__about__listname">Milage</h4>
                    <div className="productitem__about__border">
                      ..............................................................................................................................................................................................
                    </div>
                    <p className="productitem__about__listvalue">{data?.mil}</p>
                  </li>
                ) : (
                  " "
                )}
                <li className="productitem__about__listitem">
                  <h4 className="productitem__about__listname">
                    {" "}
                    Discount price
                  </h4>
                  <div className="productitem__about__border">
                    ..............................................................................................................................................................................................
                  </div>
                  <p className="productitem__about__listvalue">
                    {data?.discount_price ? data?.discount_price : 0}%
                  </p>
                </li>
                {data?.info?.map((item, index) => (
                  <li key={index} className="productitem__about__listitem">
                    <h4 className="productitem__about__listname">
                      {" "}
                      {item?.key}
                    </h4>
                    <div className="productitem__about__border">
                      ..............................................................................................................................................................................................
                    </div>
                    <p className="productitem__about__listvalue">
                      {item?.value}
                    </p>
                  </li>
                ))}
                <li className="productitem__about__listitem">
                  <h4 className="productitem__about__listname"> Condition</h4>
                  <div className="productitem__about__border">
                    ..............................................................................................................................................................................................
                  </div>

                  <p
                    style={{ color: `${data?.condition?.color} ` }}
                    className="market__product__aboutlist__item__text "
                  >
                    {data?.condition?.name}
                  </p>
                </li>
                {data?.keyboard ? (
                  <li className="productitem__about__listitem">
                    <h4 className="productitem__about__listname">
                      {" "}
                      Keyboard layout
                    </h4>
                    <div className="productitem__about__border">
                      ..............................................................................................................................................................................................
                    </div>

                    <p className="market__product__aboutlist__item__text">
                      {data?.keyboard_layout?.name}
                    </p>
                  </li>
                ) : (
                  ""
                )}
                {data?.battery_cycles ? (
                  <li className="productitem__about__listitem">
                    <h5 className="productitem__about__listname">
                      Battery Cycles{" "}
                      <Tippy content="Battery cycle count helps to determine the number of times your battery has been fully charged.">
                        <span
                          style={{
                            font: "bold",
                            cursor: "pointer",
                            color: "red",
                          }}
                        >
                          (i)
                        </span>
                      </Tippy>
                    </h5>
                    <span className="productitem__about__border">
                      ..............................................................................................................................................................................................
                    </span>
                    <p className="market__product__aboutlist__item__text">
                      {data?.battery_cycles}
                    </p>
                  </li>
                ) : (
                  ""
                )}
                {data?.office_name ? (
                  <li className="productitem__about__listitem">
                    <h5 className="productitem__about__listname">
                      Office location
                    </h5>
                    <span className="productitem__about__border">
                      ..............................................................................................................................................................................................
                    </span>
                    <p className="market__product__aboutlist__item__text">
                      {data?.office_name}
                    </p>
                  </li>
                ) : (
                  ""
                )}
                {data?.paided_day ? (
                  <li className="productitem__about__listitem">
                    <h5 className="productitem__about__listname">
                      Date of Purchase
                    </h5>
                    <span className="productitem__about__border">
                      ..............................................................................................................................................................................................
                    </span>
                    <p className="market__product__aboutlist__item__text">
                      {data?.paided_day}
                    </p>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              <p className="productitem__description">
                {data?.description?.split("\r\n")?.map((item, index) => (
                  <p dangerouslySetInnerHTML={{ __html: item }} key={index}></p>
                ))}
              </p>
              <h4 className="productitem__price">
                {" "}
                {data?.price} {data?.currency_title}
              </h4>
            </div>
            <button
              onClick={() => {
                if (data?.limit === "no_limit") {
                  setModalCount(true);
                } else {
                  handleOrder(data?.id);
                }
              }}
              className="producitem__orderbtn"
            >
              Add Order
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Productitem;
