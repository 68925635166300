import React, { useContext, useEffect, useState } from "react";
import sortarrow from "../../assets/Images/svg/sortarrow.svg";
import searchicon from "../../assets/Images/svg/searchimg.svg";
import previmg from "../../assets/Images/svg/prevnavigate.svg";
import closemodalimg from "../../assets/Images/svg/closemodal.svg";
import "./market.css";
import Checkbox, { Simplecheckbox } from "../../components/Checkbox/Checkbox";
import Modal from "../../components/Modal/Modal";
import Preloader from "../../components/Preloader/Preloader";
import http from "../../service/axios";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useDebounce } from "../../hooks/useDebance";
import Scrolltop from "../../components/Scrolltop/Scrolltop";
import { Context } from "../../context/Context";
import selectimg from "../../assets/Images/svg/select.svg";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
const Market = () => {
  const { setAutorModal } = useContext(Context);
  // Helper to get from session storage
  const getSessionStorage = (key, defaultValue) => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  };

  // Helper to set to session storage
  const setSessionStorage = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  };
  const { id } = useParams();
  const [cookies, setCookie] = useCookies(["token"]);
  const [scrolltop, setScrolltop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [catalogcheckboxes, setCatalogCheckboxes] = useState(
    getSessionStorage("catalogcheckboxes", [])
  );
  const [officecheckboxes, setOfficeCheckboxes] = useState(
    getSessionStorage("officecheckboxes", [])
  );
  const [organizationcheckboxes, setOrganizationCheckboxes] = useState(
    getSessionStorage("organizationcheckboxes", [])
  );
  const [catalogselect, setCatalogselect] = useState(
    getSessionStorage("catalogselect", "")
  );
  const [filterarr, setFilterarr] = useState(
    getSessionStorage("filterarr", [])
  );
  const [keyboard, setKeyboard] = useState([]);
  const [selectedKeyboard, setSelectedKeyboard] = useState(
    getSessionStorage("selectedKeyboard", [])
  );
  const [condition, setCondition] = useState([]);
  const [conditiontype, setConditiontype] = useState(
    getSessionStorage("conditiontype", false)
  );
  const [selectedCondition, setSelectedCondition] = useState(
    getSessionStorage("selectedCondition", [])
  );
  const [keyboardtype, setKeyboardtype] = useState(
    getSessionStorage("keyboardtype", false)
  );
  const [dicount, setDiscount] = useState(getSessionStorage("dicount", false));
  const [hasKeyboard, setHaskeyboard] = useState(false);

  useEffect(() => {
    setSessionStorage("catalogcheckboxes", catalogcheckboxes);
  }, [catalogcheckboxes]);

  useEffect(() => {
    setSessionStorage("officecheckboxes", officecheckboxes);
  }, [officecheckboxes]);

  useEffect(() => {
    setSessionStorage("organizationcheckboxes", organizationcheckboxes);
  }, [organizationcheckboxes]);

  useEffect(() => {
    setSessionStorage("catalogselect", catalogselect);
  }, [catalogselect]);

  useEffect(() => {
    setSessionStorage("filterarr", filterarr);
  }, [filterarr]);

  useEffect(() => {
    setSessionStorage("selectedKeyboard", selectedKeyboard);
  }, [selectedKeyboard]);

  useEffect(() => {
    setSessionStorage("conditiontype", conditiontype);
  }, [conditiontype]);

  useEffect(() => {
    setSessionStorage("selectedCondition", selectedCondition);
  }, [selectedCondition]);

  useEffect(() => {
    setSessionStorage("keyboardtype", keyboardtype);
  }, [keyboardtype]);

  useEffect(() => {
    setSessionStorage("dicount", dicount);
  }, [dicount]);

  const handleDicount = () => {
    setDiscount(!dicount);
  };

  const navigate = useNavigate();
  const getKeyboard = () => {
    http
      .get("/api/v1/keyboard/")
      .then((res) => {
        setKeyboard(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get("api/v1/condition")
      .then((res) => {
        setCondition(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getKeyboard();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolltop(true);
      } else {
        setScrolltop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // ---- showmore --- //
  const [productcount, setProductCount] = useState(0);
  const [activenum, setActivenum] = useState(getSessionStorage("activenum", 1));

  useEffect(() => {
    setSessionStorage("activenum", activenum);
  }, [activenum]);

  const handleShowmore = () => {
    setActivenum((prev) => prev + 1);
  };

  // --- search -- //
  const [search, setSearch] = useState(getSessionStorage("search", ""));
  const searchDebance = useDebounce(search, 500);

  useEffect(() => {
    setSessionStorage("search", search);
  }, [search]);

  useEffect(() => {
    getKeyboard();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolltop(true);
      } else {
        setScrolltop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [modal, setModal] = useState(false);
  const [modalcontent, setModalcontent] = useState("Inexpensive first");
  const handleCheck = (item) => {
    setModalcontent(item);
    closeModal();
  };
  const closeModal = () => {
    setModal(false);
  };
  const openModal = () => {
    setModal(true);
  };

  // ---- Product ---
  const [product, setProduct] = useState([]);
  const getProduct = () => {
    setLoading(true);
    let strings = "";
    for (let i = 0; i < catalogcheckboxes.length; i++) {
      strings += `&cats=${catalogcheckboxes[i]}`;
    }
    let office = "";
    for (let i = 0; i < officecheckboxes.length; i++) {
      office += `&offices=${officecheckboxes[i]}`;
    }
    let orgs = "";
    for (let i = 0; i < organizationcheckboxes.length; i++) {
      orgs += `&orgs=${organizationcheckboxes[i]}`;
    }
    let keyboard = "";
    for (let i = 0; i < selectedKeyboard.length; i++) {
      keyboard += `&key=${selectedKeyboard[i]}`;
    }
    let condition = "";
    for (let i = 0; i < selectedCondition.length; i++) {
      condition += `&cond=${selectedCondition[i]}`;
    }
    let soryby = "price";
    if (modalcontent === "Inexpensive first") {
      soryby = "price";
    } else if (modalcontent === "Expensive first") {
      soryby = "-price";
    } else if (modalcontent === "A-Z") {
      soryby = "title";
    } else if (modalcontent === "Z-A") {
      soryby = "-title";
    }
    http
      .get(
        `/api/v1/product/list/?search=${searchDebance}&limit=${
          activenum * 6
        }&parent_cat=${id}${strings !== "" ? strings : "&cats="}
        ${office !== "" ? office : "&offices="}${orgs !== "" ? orgs : "&orgs="}
        &${condition !== "" ? condition : "&con="}&${
          keyboard !== "" ? keyboard : "&key="
        }&ordering=${soryby}&has_discount=${dicount}`,
        {
          headers: {
            Cookie: `${cookies.token}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setProductCount(res?.data?.count);
        setProduct(res?.data?.results);
        setCatalog(res.data?.categories);
        setHaskeyboard(res.data?.has_keyboard);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401) {
          setAutorModal(true);
        }
      });
  };
  useEffect(() => {
    getProduct();
  }, [
    searchDebance,
    catalogcheckboxes,
    officecheckboxes,
    organizationcheckboxes,
    selectedCondition,
    selectedKeyboard,
    activenum,
    modalcontent,
    dicount,
  ]);

  // ---- Information ---
  const [information, setInformation] = useState("");
  const getInformation = () => {
    http
      .get("/api/v1/information/list/")
      .then((res) => {
        setInformation(res.data[0]?.text);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getInformation();
  }, []);
  // --- organizations --- //
  const [organizations, setOrganizations] = useState([]);
  const handleOrganization = (id) => {
    setOrganizationCheckboxes((prevCheckboxes) => {
      if (prevCheckboxes.includes(id)) {
        return prevCheckboxes.filter((checkboxId) => checkboxId !== id);
      } else {
        return [...prevCheckboxes, id];
      }
    });
  };
  const getOrganizations = () => {
    http
      .get("/api/v1/organization/list/")
      .then((res) => {
        setOrganizations(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getOrganizations();
  }, []);
  // --- office --- //
  const [office, setOffice] = useState([]);
  const handleOfficeChecbox = (id) => {
    setOfficeCheckboxes((prevCheckboxes) => {
      if (prevCheckboxes.includes(id)) {
        return prevCheckboxes.filter((checkboxId) => checkboxId !== id);
      } else {
        return [...prevCheckboxes, id];
      }
    });
  };
  const getOffice = () => {
    http
      .get("/api/v1/office/list/")
      .then((res) => {
        setOffice(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getOffice();
  }, []);
  // -- catalog -- //
  const [catalog, setCatalog] = useState([]);
  const [catalogoptions, setCatalogoptions] = useState([]);
  const getCatalogOptions = () => {
    http
      .get(`/api/v1/category/list/?parent=${id}`)
      .then((res) => {
        setCatalogoptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCatalogchekboxes = (id) => {
    setCatalogCheckboxes((prevCheckboxes) => {
      if (prevCheckboxes.includes(id)) {
        return prevCheckboxes.filter((checkboxId) => checkboxId !== id);
      } else {
        return [...prevCheckboxes, id];
      }
    });
  };
  const handleConditionChekboxed = (id) => {
    setSelectedCondition((prevCheckboxes) => {
      if (prevCheckboxes.includes(id)) {
        return prevCheckboxes.filter((checkboxId) => checkboxId !== id);
      } else {
        return [...prevCheckboxes, id];
      }
    });
  };
  const handleKeyboarChekboxed = (id) => {
    setSelectedKeyboard((prevCheckboxes) => {
      if (prevCheckboxes.includes(id)) {
        return prevCheckboxes.filter((checkboxId) => checkboxId !== id);
      } else {
        return [...prevCheckboxes, id];
      }
    });
  };

  const handleClear = () => {
    setCatalogCheckboxes([]);
    setOfficeCheckboxes([]);
    setOrganizationCheckboxes([]);
    setSearch("");
    setCatalogselect("");
    setCatalogoptions([]);
    setActivenum(1);
    setFilterarr([]);
    setSelectedCondition([]);
    setSelectedKeyboard([]);
    setConditiontype(false);
    setKeyboardtype(false);
    setDiscount(false);
  };
  const [detail, setDetail] = useState({});
  const getCategoryDetail = () => {
    http
      .get(`/api/v1/category/detail/${id}/`)
      .then((res) => {
        setDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCategoryDetail();
    getCatalogOptions();
  }, [id, catalog]);
  const handleToggleId = (id) => {
    setFilterarr((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((prevId) => prevId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };
  return (
    <section className="market">
      {scrolltop && <Scrolltop />}
      {loading && <Preloader />}
      <Modal isOpen={modal} onClose={closeModal}>
        <div className="modal__sortby">
          <h4 className="modal__sortby__title">Sort by:</h4>
          <ul className="modal__sortby__list">
            <li className="modal__sortby__listitem">
              <h5 className="modal__sortby__item">Inexpensive first</h5>
              <div
                onClick={() => handleCheck("Inexpensive first")}
                className="modal__sortby__radio"
              >
                {modalcontent === "Inexpensive first" ? (
                  <div className="modal__sortby__radio__item"></div>
                ) : (
                  ""
                )}
              </div>
            </li>
            <li className="modal__sortby__listitem">
              <h5 className="modal__sortby__item">Expensive first</h5>
              <div
                onClick={() => handleCheck("Expensive first")}
                className="modal__sortby__radio"
              >
                {modalcontent === "Expensive first" ? (
                  <div className="modal__sortby__radio__item"></div>
                ) : (
                  ""
                )}
              </div>
            </li>
            <li className="modal__sortby__listitem">
              <h5 className="modal__sortby__item">A-Z</h5>
              <div
                onClick={() => handleCheck("A-Z")}
                className="modal__sortby__radio"
              >
                {modalcontent === "A-Z" ? (
                  <div className="modal__sortby__radio__item"></div>
                ) : (
                  ""
                )}
              </div>
            </li>
            <li className="modal__sortby__listitem">
              <h5 className="modal__sortby__item">Z-A</h5>
              <div
                onClick={() => handleCheck("Z-A")}
                className="modal__sortby__radio"
              >
                {modalcontent === "Z-A" ? (
                  <div className="modal__sortby__radio__item"></div>
                ) : (
                  ""
                )}
              </div>
            </li>
          </ul>
          <button onClick={() => closeModal()} className="modal__closebtn">
            <img src={closemodalimg} alt="" />
          </button>
        </div>
      </Modal>
      <h2 className="market__title">
        <div>
          <div
            onClick={() => {
              handleClear();
              navigate(-1);
            }}
            className="market__prevnavigate"
          >
            <img src={previmg} alt="" />
            <span className="market__prevmobiletext">Back</span>
          </div>
        </div>{" "}
        <span className="matekt__title__text">{detail?.name}</span>
        <button className="market__limit">
          Limit: {detail?.limit === "no_limit" ? "No Limit" : detail?.limit}
        </button>
      </h2>
      <div className="market__sortwrapper">
        <div className="market__sortbox">
          <h4 className="market__sorttext">Sort by:</h4>
          <h5 onClick={() => openModal()} className="market__sorttitle">
            {modalcontent} <img src={sortarrow} alt="" />
          </h5>
        </div>
      </div>
      <div className="market__mainwrapper">
        <div className="market__filterbox">
          <div className="market__filter">
            <label className="market__search" htmlFor="">
              <img src={searchicon} alt="" className="market__searchimg" />
              <input
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search..."
                className="market__search-input"
                type="text"
                value={search}
              />
            </label>
            <div className="market__bottom__sort">
              <div className="market__inner__filter">
                <h3 className="market__inner__filter__title">Organization</h3>
                <ul className="market__inner__filter__list">
                  {organizations?.map((item, index) => (
                    <li key={index}>
                      <Checkbox
                        id={item?.id}
                        array={organizationcheckboxes}
                        handleChange={() => handleOrganization(item?.id)}
                      />
                      <p className="market__inner__filter__checktext">
                        {item?.title}
                      </p>
                    </li>
                  ))}
                </ul>
                <ul className="market__inner__filter__mobile__list">
                  {organizations?.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => handleOrganization(item?.id)}
                      className={
                        organizationcheckboxes?.includes(item?.id)
                          ? "market__inner__filter__mobile__list__item  market__inner__filter__mobile__list__item__active"
                          : "market__inner__filter__mobile__list__item"
                      }
                    >
                      {item?.title}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="market__inner__filter">
                <h3 className="market__inner__filter__title">Office</h3>
                <ul className="market__inner__filter__list">
                  {office?.map((item, index) => {
                    return (
                      <li key={index}>
                        <Checkbox
                          id={item?.id}
                          array={officecheckboxes}
                          handleChange={() => handleOfficeChecbox(item?.id)}
                        />
                        <p className="market__inner__filter__checktext">
                          {item.title}
                        </p>
                      </li>
                    );
                  })}
                </ul>
                <ul className="market__inner__filter__mobile__list">
                  {office?.map((item, index) => (
                    <li
                      onClick={() => handleOfficeChecbox(item?.id)}
                      key={index}
                      className={
                        officecheckboxes.includes(item?.id)
                          ? "market__inner__filter__mobile__list__item  market__inner__filter__mobile__list__item__active"
                          : "market__inner__filter__mobile__list__item"
                      }
                    >
                      {item.title}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="market__sort__catalog">
                <h3 className="market__sort__catalog__title">Filters</h3>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    marginBottom: "10px",
                  }}
                >
                  <Simplecheckbox
                    type={dicount}
                    toggleCheckbox={handleDicount}
                  />
                  <p className="market__inner__filter__checktext">
                    Discount price
                  </p>
                </div>
                <ul className="market__sort__catalog__list">
                  {hasKeyboard ? (
                    <li className="market__sort__catalog__item">
                      <h5
                        onClick={() => setKeyboardtype(!keyboardtype)}
                        className="market__sort__catalog__item__title dropbox"
                      >
                        Keyboard Layout
                        <img
                          className={keyboardtype && "img__rotate"}
                          src={selectimg}
                          alt="arrow"
                        />
                      </h5>
                      {keyboardtype && (
                        <ul className="market__sort__catalog__item__list">
                          {keyboard?.map((item, index) => (
                            <li
                              key={index}
                              className="market__sort__catalog__item__list__item"
                            >
                              <Checkbox
                                id={item?.id}
                                array={selectedKeyboard}
                                handleChange={() =>
                                  handleKeyboarChekboxed(item?.id)
                                }
                              />
                              <p className="market__sort__catalog__item__checktext">
                                {item?.name}
                              </p>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ) : (
                    ""
                  )}
                  <li className="market__sort__catalog__item">
                    <h5
                      onClick={() => setConditiontype(!conditiontype)}
                      className="market__sort__catalog__item__title dropbox"
                    >
                      Condition
                      <img
                        className={conditiontype && "img__rotate"}
                        src={selectimg}
                        alt="arrow"
                      />
                    </h5>
                    {conditiontype && (
                      <ul className="market__sort__catalog__item__list">
                        {condition?.map((item, index) => (
                          <li
                            key={index}
                            className="market__sort__catalog__item__list__item"
                          >
                            <Checkbox
                              id={item?.id}
                              array={selectedCondition}
                              handleChange={() =>
                                handleConditionChekboxed(item?.id)
                              }
                            />
                            <p
                              style={{ color: `${item?.color} ` }}
                              className="market__sort__catalog__item__checktext"
                            >
                              {item?.name}
                            </p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  {catalogoptions?.map((item, index) => (
                    <li key={index} className="market__sort__catalog__item">
                      <h5
                        onClick={() => handleToggleId(item?.id)}
                        className="market__sort__catalog__item__title dropbox"
                      >
                        {item?.name}
                        <img
                          className={
                            filterarr?.includes(item?.id) && "img__rotate"
                          }
                          src={selectimg}
                          alt="arrow"
                        />
                      </h5>
                      {filterarr?.includes(item?.id) ? (
                        <ul className="market__sort__catalog__item__list">
                          {item?.children?.map((item, index) => (
                            <>
                              {item?.children?.length > 0 ? (
                                <>
                                  <h5 className="market__sort__catalog__item__title">
                                    {item?.name}
                                  </h5>
                                  <ul className="market__sort__catalog__list">
                                    {item?.children?.map((item, index) => (
                                      <li
                                        key={index}
                                        className="market__sort__catalog__item__list__item"
                                      >
                                        <Checkbox
                                          id={item?.id}
                                          array={catalogcheckboxes}
                                          handleChange={() =>
                                            handleCatalogchekboxes(item?.id)
                                          }
                                        />
                                        <p className="market__sort__catalog__item__checktext">
                                          {item?.name}
                                        </p>
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              ) : (
                                <li
                                  key={index}
                                  className="market__sort__catalog__item__list__item"
                                >
                                  <Checkbox
                                    id={item?.id}
                                    array={catalogcheckboxes}
                                    handleChange={() =>
                                      handleCatalogchekboxes(item?.id)
                                    }
                                  />
                                  <p className="market__sort__catalog__item__checktext">
                                    {item?.name}
                                  </p>
                                </li>
                              )}
                            </>
                          ))}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ul>
                <ul className="market__sort__catalog__list__mobile">
                  {hasKeyboard ? (
                    <li
                      style={{ borderBottom: "1px solid" }}
                      className="market__sort__catalog__item__mobile"
                    >
                      <h5
                        style={{ fontSize: "18px !important" }}
                        onClick={() => setKeyboardtype(!keyboardtype)}
                        className="market__sort__catalog__item__title dropbox"
                      >
                        Keyboard Layout
                        <imgs
                          className={keyboardtype && "img__rotate"}
                          width={14}
                          src={selectimg}
                          alt="arrow"
                        />
                      </h5>
                      {keyboardtype && (
                        <ul
                          style={{ marginBottom: "5px" }}
                          className="market__sort__catalog__item__list__mobile"
                        >
                          {keyboard?.map((item, index) => (
                            <li
                              onClick={() => handleKeyboarChekboxed(item?.id)}
                              key={index}
                              className={
                                selectedKeyboard?.includes(item?.id)
                                  ? "market__inner__filter__mobile__list__item  market__inner__filter__mobile__list__item__active"
                                  : "market__inner__filter__mobile__list__item "
                              }
                            >
                              {item?.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ) : (
                    ""
                  )}
                  <li
                    style={{ borderBottom: "1px solid" }}
                    className="market__sort__catalog__item__mobile"
                  >
                    <h5
                      style={{ fontSize: "18px !important" }}
                      onClick={() => setConditiontype(!conditiontype)}
                      className="market__sort__catalog__item__title dropbox"
                    >
                      Condition
                      <img
                        className={conditiontype && "img__rotate"}
                        width={14}
                        src={selectimg}
                        alt="arrow"
                      />
                    </h5>
                    {conditiontype && (
                      <ul
                        style={{ marginBottom: "5px" }}
                        className="market__sort__catalog__item__list__mobile"
                      >
                        {condition?.map((item, index) => (
                          <li
                            onClick={() => handleConditionChekboxed(item?.id)}
                            key={index}
                            className={
                              selectedCondition?.includes(item?.id)
                                ? "market__inner__filter__mobile__list__item  market__inner__filter__mobile__list__item__active"
                                : "market__inner__filter__mobile__list__item "
                            }
                          >
                            {item?.name}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                  {catalogoptions?.map((item, index) => (
                    <li
                      key={index}
                      style={{ borderBottom: "1px solid" }}
                      className="market__sort__catalog__item__mobile"
                    >
                      <h5
                        style={{ fontSize: "18px !important" }}
                        onClick={() => handleToggleId(item?.id)}
                        className="market__sort__catalog__item__title dropbox"
                      >
                        {item?.name}
                        <img
                          className={
                            filterarr?.includes(item?.id) && "img__rotate"
                          }
                          width={14}
                          src={selectimg}
                          alt="arrow"
                        />
                      </h5>
                      {filterarr?.includes(item?.id) && (
                        <ul
                          style={{ marginBottom: "5px" }}
                          className="market__sort__catalog__item__list__mobile"
                        >
                          {item?.children?.map((item, index) => (
                            <>
                              {item?.children?.length > 0 ? (
                                <li style={{ width: "100%" }}>
                                  <h5 className="market__sort__catalog__item__title">
                                    {item?.name}
                                  </h5>
                                  <ul className="market__sort__catalog__item__list__mobile">
                                    {item?.children?.map((item, index) => (
                                      <li
                                        onClick={() =>
                                          handleCatalogchekboxes(item?.id)
                                        }
                                        key={index}
                                        className={
                                          catalogcheckboxes?.includes(item?.id)
                                            ? "market__inner__filter__mobile__list__item  market__inner__filter__mobile__list__item__active"
                                            : "market__inner__filter__mobile__list__item "
                                        }
                                      >
                                        {item?.name}
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              ) : (
                                <li
                                  onClick={() =>
                                    handleCatalogchekboxes(item?.id)
                                  }
                                  key={index}
                                  className={
                                    catalogcheckboxes?.includes(item?.id)
                                      ? "market__inner__filter__mobile__list__item  market__inner__filter__mobile__list__item__active"
                                      : "market__inner__filter__mobile__list__item "
                                  }
                                >
                                  {item?.name}
                                </li>
                              )}
                            </>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              <button
                onClick={() => handleClear()}
                className="market__sort__catalog__clearbtn"
              >
                Clear all
              </button>
            </div>
          </div>
        </div>
        <div className="marketplan__sortwrapper">
          <div className="market__sortbox">
            <h4 className="market__sorttext">Sort by:</h4>
            <h5 onClick={() => openModal()} className="market__sorttitle">
              {modalcontent} <img src={sortarrow} alt="" />
            </h5>
          </div>
        </div>
        <div style={{ width: "100%" }} className="marker__product__wrapper">
          {product?.length > 0 ? (
            <ul className="market__product__list">
              {product?.map((item, index) => (
                <li
                  onClick={() => navigate(`/product/${item.id}`)}
                  key={index}
                  className="market__product__list__item"
                >
                  <div className="market__product__listsale">
                    {item?.discount_price ? (
                      <span className="market__product__listsale__btn ">
                        -{item?.discount_price}%
                      </span>
                    ) : (
                      ""
                    )}
                    {item?.limit === "no_limit" ? (
                      <span className="marker__product__list__unlimit market__product__listsale__btn ">
                        Unlimited
                      </span>
                    ) : (
                      ""
                    )}
                  </div>

                  <div
                    style={{
                      backgroundImage: `url(${item?.images?.at(0)?.image})`,
                      marginBottom: "5px",
                    }}
                    className="marker__product__img"
                  ></div>
                  <div className="market__product__info">
                    <h4 className="market__product__title">{item?.title}</h4>
                    <ul className="market__product__aboutlist">
                      <li className="marker__product__aboutlist__item">
                        <h5 className="market__product__aboutlist__item__title">
                          Name
                        </h5>
                        <span className="marker__product__about__border">
                          ..............................................................................................................................................................................................
                        </span>
                        <p className="market__product__aboutlist__item__text">
                          {item?.title}
                        </p>
                      </li>
                      <li className="marker__product__aboutlist__item">
                        <h5 className="market__product__aboutlist__item__title">
                          Qty
                        </h5>
                        <span className="marker__product__about__border">
                          ..............................................................................................................................................................................................
                        </span>
                        <p className="market__product__aboutlist__item__text">
                          {item?.count}
                        </p>
                      </li>
                      {item?.is_car ? (
                        <li className="marker__product__aboutlist__item">
                          <h5 className="market__product__aboutlist__item__title">
                            Milage
                          </h5>
                          <span className="marker__product__about__border">
                            ..............................................................................................................................................................................................
                          </span>
                          <p className="market__product__aboutlist__item__text">
                            {item?.mil}
                          </p>
                        </li>
                      ) : (
                        ""
                      )}
                      {item?.discount_price ? (
                        <li className="marker__product__aboutlist__item">
                          <h5 className="market__product__aboutlist__item__title">
                            Discount price
                          </h5>
                          <span className="marker__product__about__border">
                            ..............................................................................................................................................................................................
                          </span>
                          <p className="market__product__aboutlist__item__text">
                            {item?.discount_price ? item?.discount_price : 0}%
                          </p>
                        </li>
                      ) : (
                        " "
                      )}
                      {item?.info?.map((item, index) => (
                        <li
                          key={index}
                          className="marker__product__aboutlist__item"
                        >
                          <h5 className="market__product__aboutlist__item__title">
                            {item?.key}
                          </h5>
                          <span className="marker__product__about__border">
                            ..............................................................................................................................................................................................
                          </span>
                          <p className="market__product__aboutlist__item__text">
                            {item?.value}
                          </p>
                        </li>
                      ))}
                      <li className="marker__product__aboutlist__item">
                        <h5 className="market__product__aboutlist__item__title">
                          Condition
                        </h5>
                        <span className="marker__product__about__border">
                          ..............................................................................................................................................................................................
                        </span>
                        <p
                          style={{ color: `${item?.condition?.color} ` }}
                          className="market__product__aboutlist__item__text "
                        >
                          {item?.condition?.name}
                        </p>
                      </li>
                      {item?.keyboard ? (
                        <li className="marker__product__aboutlist__item">
                          <h5 className="market__product__aboutlist__item__title">
                            Keyboard layout
                          </h5>
                          <span className="marker__product__about__border">
                            ..............................................................................................................................................................................................
                          </span>
                          <p className="market__product__aboutlist__item__text ">
                            {item?.keyboard_layout?.name}
                          </p>
                        </li>
                      ) : (
                        " "
                      )}
                      {item?.battery_cycles ? (
                        <li className="marker__product__aboutlist__item">
                          <h5 className="market__product__aboutlist__item__title">
                            Battery Cycles{" "}
                            <Tippy content="Battery cycle count helps to determine the number of times your battery has been fully charged.">
                              <span
                                style={{
                                  font: "bold",
                                  cursor: "pointer",
                                  color: "red",
                                }}
                              >
                                (i)
                              </span>
                            </Tippy>
                          </h5>
                          <span className="marker__product__about__border">
                            ..............................................................................................................................................................................................
                          </span>
                          <p className="market__product__aboutlist__item__text">
                            {item?.battery_cycles}
                          </p>
                        </li>
                      ) : (
                        ""
                      )}
                      {item?.office_name ? (
                        <li className="marker__product__aboutlist__item">
                          <h5 className="market__product__aboutlist__item__title">
                            Office location
                          </h5>
                          <span className="marker__product__about__border">
                            ..............................................................................................................................................................................................
                          </span>
                          <p className="market__product__aboutlist__item__text">
                            {item?.office_name}
                          </p>
                        </li>
                      ) : (
                        ""
                      )}
                      {item?.paided_day ? (
                        <li className="marker__product__aboutlist__item">
                          <h5 className="market__product__aboutlist__item__title">
                            Date of Purchase
                          </h5>
                          <span className="marker__product__about__border">
                            ..............................................................................................................................................................................................
                          </span>
                          <p className="market__product__aboutlist__item__text">
                            {item?.paided_day}
                          </p>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                    {/* <button className="market__product__correct__btn">
                    <img src={correctimg} alt="" />
                     {item?.price} {item?.currency_title}
                  </button> */}
                    <button className="market__product__simplebtn">
                      {item?.price} {item?.currency_title}
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <h4
              style={{
                width: "100%",
                textAlign: "center",
                marginBottom: "20px",
                fontSize: "20px",
                color: "rgb(131, 134, 143)",
              }}
            >
              There are no products available now
            </h4>
          )}
          {activenum * 6 < productcount && product?.length > 0 ? (
            <button
              onClick={() => handleShowmore()}
              className="market__showmore__btn"
            >
              Show More
            </button>
          ) : (
            ""
          )}
          <div className="market__information__wrapper">
            <h3 className="market__information__title">Information</h3>
            <div className="market__information__box">
              {information?.split("\r\n")?.map((item, index) => (
                <p
                  className="market__information__box__doc"
                  style={{ marginBottom: "6px" }}
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                ></p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Market;
