import React from "react";
import scrolltopimg from "../../assets/Images/svg/srolltop.svg";
import "./srcolltop.css";
const Scrolltop = () => {
  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <button onClick={() => handleTop()} className="scrolltop__btn">
      <img src={scrolltopimg} alt="" />
    </button>
  );
};

export default Scrolltop;
