import React, { useContext, useEffect, useState } from "react";
import "./history.css";
import searchicon from "../../assets/Images/svg/searchimg.svg";
import http from "../../service/axios";
import CountdownTimer from "./CounterDownTimer";
import { useDebounce } from "../../hooks/useDebance";
import Preloader from "../../components/Preloader/Preloader";
import { Context } from "../../context/Context";
const History = () => {
  const [orderhistory, setOrderhistory] = useState([]);
  const { setAutorModal } = useContext(Context);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const searchDebance = useDebounce(search, 500);
  const getOrderhistory = () => {
    setLoading(true);
    http
      .get(`/api/v1/order/list/?title=${searchDebance}`)
      .then((res) => {
        setOrderhistory(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 401) {
          setAutorModal(true);
        }
      });
  };
  useEffect(() => {
    getOrderhistory();
  }, [searchDebance]);
  return (
    <section className="history">
      {loading && <Preloader />}
      <h2 className="history__title">Orders History</h2>
      <form className="history__form" action="">
        <label className="history__label" htmlFor="">
          <button className="history__searchbtn">
            <img className="history__searchimg" src={searchicon} alt="" />
          </button>
          <input
            placeholder="Search..."
            className="history__search-input"
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </label>
      </form>
      <div className="history__wrapper">
        <table className="history__table">
          <thead className="history__table__thead">
            <tr className="history__tr">
              <th className="history__th">Order ID</th>
              <th className="history__th">Title</th>
              <th className="history__th">Qty</th>
              <th className="history__th">Discount</th>
              <th className="history__th">Price</th>
              <th className="history__th">Total Price</th>
              <th className="history__th">Currency</th>
              <th className="history__th">Application date</th>
              <th className="history__th">Status</th>
              <th className="history__th">Expired time</th>
            </tr>
          </thead>
          <tbody>
            {orderhistory?.map((item, index) => (
              <tr key={index}>
                <td className="history__td">{item?.id}</td>
                <td className="history__td">{item?.title}</td>
                <td className="history__td">{item?.count}</td>
                <td className="history__td">
                  {item?.discount ? item?.discount : 0} %
                </td>
                <td className="history__td">{item?.price} </td>
                <td className="history__td">{item?.price * item?.count} </td>
                <td className="history__td">{item?.currency}</td>
                <td className="history__td">
                  {item?.created_at?.slice(0, 10)}
                </td>
                <td className="history__td">{item?.status}</td>
                <td className="history__td">
                  {item?.status === "Under consideration" ? (
                    <CountdownTimer data={`${item.created_at}`} />
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default History;
