import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import http from "../service/axios";
import { useNavigate } from "react-router-dom";
const Context = createContext();
const ContextProvider = ({ children }) => {
  const [setCookies, cookies, removeCookie] = useCookies(["token"]);
  const [opensidebar, setOpenSidebar] = useState(false);
  const [descsidebar, setDescSidebar] = useState(false);
  const [userabout, setUserAbout] = useState({});
  const [userrefresh, setUserrefresh] = useState(false);
  const [itemaddmodal, setItemaddmodal] = useState(false);
  const [authormodal, setAutorModal] = useState(false);
  const [infotext, setInfotext] = useState("");
  const [orderModal, setOrderModal] = useState(true);
  const navigate = useNavigate();
  const openAddmodal = () => {
    setItemaddmodal(true);
  };
  const closeAddmodal = () => {
    setItemaddmodal(false);
  };
  const handleReresh = () => {
    setUserrefresh(!userrefresh);
  };

  const handleLogout = () => {
    removeCookie("token", { path: "/" });
    navigate("/");
    window.location.reload();
  };

  return (
    <Context.Provider
      value={{
        opensidebar,
        setOpenSidebar,
        descsidebar,
        setDescSidebar,
        userabout,
        setUserAbout,
        handleReresh,
        itemaddmodal,
        openAddmodal,
        closeAddmodal,
        handleLogout,
        authormodal,
        setAutorModal,
        infotext,
        setInfotext,
        orderModal,
        setOrderModal,
        setUserAbout,
        userrefresh,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
