import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
// --- Images_--
import hamburimg from "../../assets/Images/svg/hambur.svg";
import mainlogo from "../../assets/Images/svg/mainlogo.svg";
import minisitelogo from "../../assets/Images/svg/minisitelogo.svg";
import marketlogo from "../../assets/Images/svg/marketlogo.svg";
import marketactivelogo from "../../assets/Images/svg/marketactivelogo.svg";
import rulegimg from "../../assets/Images/svg/rules.svg";
import ruleimgactive from "../../assets/Images/svg/rulesactive.svg";
import orderimg from "../../assets/Images/svg/order.svg";
import orderimgactive from "../../assets/Images/svg/orderactive.svg";
import historyimg from "../../assets/Images/svg/history.svg";
import historyimgactive from "../../assets/Images/svg/historyactive.svg";
import profileimg from "../../assets/Images/svg/profile.svg";
import profileimgactive from "../../assets/Images/svg/profileactive.svg";
import logginimg from "../../assets/Images/svg/login.svg";
import logginimgactive from "../../assets/Images/svg/loginactive.svg";
import logoutimg from "../../assets/Images/svg/logout.svg";
import logoutimgactive from "../../assets/Images/svg/logoutactive.svg";
// -- css --
import "./sidebar.css";
import { useCookies } from "react-cookie";
import { Context } from "../../context/Context";
import http from "../../service/axios";
const Sidebar = () => {
  const [asklink, setAsklink] = useState("");
  const {
    setOpenSidebar,
    descsidebar,
    setDescSidebar,
    setAutorModal,
    setUserAbout,
    userrefresh,
  } = useContext(Context);
  const getAsklink = () => {
    http
      .get("/api/v1/url/")
      .then((res) => {
        setAsklink(res.data?.at(0)?.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAsklink();
  }, []);
  const [cookies] = useCookies(["token"]);
  useEffect(() => {
    if (cookies.token) {
      http
        .get("/profile/user-me/")
        .then((res) => {
          setUserAbout(res?.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setAutorModal(true);
          } else if (err.response.status === 403) {
            setAutorModal(true);
          }
        });
    }
  }, [userrefresh]);

  return (
    <div className={!descsidebar ? "sidebar" : "sidebar sidebar__close"}>
      <div className="sidebar__wrapper">
        <div className="sidebar__main">
          <div
            onClick={() => {
              setDescSidebar(!descsidebar);
            }}
            className={
              !descsidebar
                ? "sidebar__header"
                : "sidebar__header sidebar__header__close"
            }
          >
            <img
              className="sidebar__logo"
              src={!descsidebar ? mainlogo : minisitelogo}
              alt=""
            />
            <button
              onClick={() => setDescSidebar(!descsidebar)}
              className="sidebar__hamubur"
            >
              <img className="sidebar__hambur__img" src={hamburimg} alt="" />
            </button>
          </div>
          <nav className="sidebar__navlinks">
            <NavLink
              onClick={() => {
                setOpenSidebar(false);
              }}
              className={"sidebar__navlink"}
              to={"/"}
            >
              {({ isActive, isPending }) =>
                isActive ? (
                  <>
                    <img
                      className="sidebar__navlink__logo"
                      src={marketactivelogo}
                      alt=""
                    />{" "}
                    {!descsidebar && (
                      <h5 className="sidebar__navlink__text">Market</h5>
                    )}
                  </>
                ) : (
                  <>
                    <img
                      className="sidebar__navlink__logo"
                      src={marketlogo}
                      alt=""
                    />{" "}
                    {!descsidebar && (
                      <h5 className="sidebar__navlink__text">Market</h5>
                    )}
                  </>
                )
              }
            </NavLink>
            <NavLink
              onClick={() => {
                setOpenSidebar(false);
              }}
              className={"sidebar__navlink"}
              to={"/rules"}
            >
              {({ isActive, isPending }) =>
                isActive ? (
                  <>
                    <img
                      className="sidebar__navlink__logo"
                      src={ruleimgactive}
                      alt=""
                    />{" "}
                    {!descsidebar && (
                      <h5 className="sidebar__navlink__text">Rules</h5>
                    )}
                  </>
                ) : (
                  <>
                    <img
                      className="sidebar__navlink__logo"
                      src={rulegimg}
                      alt=""
                    />{" "}
                    {!descsidebar && (
                      <h5 className="sidebar__navlink__text">Rules</h5>
                    )}
                  </>
                )
              }
            </NavLink>
            {cookies.token && (
              <>
                <NavLink
                  onClick={() => {
                    setOpenSidebar(false);
                  }}
                  className={"sidebar__navlink"}
                  to={"/orders"}
                >
                  {({ isActive, isPending }) =>
                    isActive ? (
                      <>
                        <img
                          className="sidebar__navlink__logo"
                          src={orderimgactive}
                          alt=""
                        />{" "}
                        {!descsidebar && (
                          <h5 className="sidebar__navlink__text">Orders</h5>
                        )}
                      </>
                    ) : (
                      <>
                        <img
                          className="sidebar__navlink__logo"
                          src={orderimg}
                          alt=""
                        />{" "}
                        {!descsidebar && (
                          <h5 className="sidebar__navlink__text">Orders</h5>
                        )}
                      </>
                    )
                  }
                </NavLink>
                <NavLink
                  onClick={() => {
                    setOpenSidebar(false);
                  }}
                  className={"sidebar__navlink"}
                  to={"/history-order"}
                >
                  {({ isActive, isPending }) =>
                    isActive ? (
                      <>
                        <img
                          className="sidebar__navlink__logo"
                          src={historyimgactive}
                          alt=""
                        />{" "}
                        {!descsidebar && (
                          <h5 className="sidebar__navlink__text">
                            History Order
                          </h5>
                        )}
                      </>
                    ) : (
                      <>
                        <img
                          className="sidebar__navlink__logo"
                          src={historyimg}
                          alt=""
                        />{" "}
                        {!descsidebar && (
                          <h5 className="sidebar__navlink__text">
                            History Order
                          </h5>
                        )}
                      </>
                    )
                  }
                </NavLink>
                <NavLink
                  onClick={() => {
                    setOpenSidebar(false);
                  }}
                  className={"sidebar__navlink"}
                  to={"/profile"}
                >
                  {({ isActive, isPending }) =>
                    isActive ? (
                      <>
                        <img
                          className="sidebar__navlink__logo"
                          src={profileimgactive}
                          alt=""
                        />{" "}
                        {!descsidebar && (
                          <h5 className="sidebar__navlink__text">Profile</h5>
                        )}
                      </>
                    ) : (
                      <>
                        <img
                          className="sidebar__navlink__logo"
                          src={profileimg}
                          alt=""
                        />{" "}
                        {!descsidebar && (
                          <h5 className="sidebar__navlink__text">Profile</h5>
                        )}
                      </>
                    )
                  }
                </NavLink>
              </>
            )}

            {cookies.token ? (
              <NavLink
                onClick={() => {
                  setOpenSidebar(false);
                }}
                className={"sidebar__navlink"}
                to={"/logout"}
              >
                {({ isActive, isPending }) =>
                  isActive ? (
                    <>
                      <img
                        className="sidebar__navlink__logo"
                        src={logoutimgactive}
                        alt=""
                      />{" "}
                      {!descsidebar && (
                        <h5 className="sidebar__navlink__text">Logout</h5>
                      )}
                    </>
                  ) : (
                    <>
                      <img
                        className="sidebar__navlink__logo"
                        src={logoutimg}
                        alt=""
                      />{" "}
                      {!descsidebar && (
                        <h5 className="sidebar__navlink__text">Logout</h5>
                      )}
                    </>
                  )
                }
              </NavLink>
            ) : (
              <NavLink
                onClick={() => {
                  setOpenSidebar(false);
                }}
                className={"sidebar__navlink"}
                to={"/login"}
              >
                {({ isActive, isPending }) =>
                  isActive ? (
                    <>
                      <img
                        className="sidebar__navlink__logo"
                        src={logginimgactive}
                        alt=""
                      />{" "}
                      {!descsidebar && (
                        <h5 className="sidebar__navlink__text">Login</h5>
                      )}
                    </>
                  ) : (
                    <>
                      <img
                        className="sidebar__navlink__logo"
                        src={logginimg}
                        alt=""
                      />{" "}
                      {!descsidebar && (
                        <h5 className="sidebar__navlink__text">Login</h5>
                      )}
                    </>
                  )
                }
              </NavLink>
            )}
          </nav>
        </div>
        <div className="sidebar__footer">
          <a
            target="_blank"
            href={asklink}
            onClick={() => {
              setOpenSidebar(false);
            }}
            className={"sidebar__footer__navlink"}
            to={"/"}
          >
            <img className="sidebar__footer__navlink__logo" src="" alt="" />
            <h5 className="sidebar__footer__navlink__text">
              {!descsidebar ? "Ask a Question" : "?"}{" "}
            </h5>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
