import axios from "axios";
import { CookiesProvider, useCookies } from "react-cookie";
const cookieToken = getCookie("token");
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
const http = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

http.interceptors.request.use(
  (config) => {
    // let token = sessionStorage.getItem("token");
    // config.headers["Cookie"] = `auth_token=${cookieToken}`;
    if (cookieToken) {
      config.headers["Authorization"] = `Bearer ${cookieToken}`;
    }

    // config.headers["Accept"] = "application/json";

    // 'resolve.fallback: { "http": require.resolve("stream-http") }'
    // resolve.fallback:{ "http": false }

    //   'resolve.fallback: { "http": require.resolve("stream-http") }'
    // resolve.fallback: { "http": false }

    // config.headers["Access-Control-Allow-Origin"] = "*";
    // config.headers["Content-Type"] = "multipart/form-data";
    // config.headers['Content-Type'] = "application/json"
    return config;
  },
  (error) => Promise.reject(error)
);

export default http;
