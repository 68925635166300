import React, { useContext, useEffect, useState } from "react";
import "./category.css";
import macimg from "../../assets/Images/png/macimg.png";
import { useNavigate } from "react-router-dom";
import http from "../../service/axios";
import { Context } from "../../context/Context";
const Category = () => {
  const { setAutorModal } = useContext(Context);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const getData = () => {
    http
      .get("api/v1/category/list/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err?.response.status === 401) {
          setAutorModal(true);
        }
      });
  };
  useEffect(() => {
    getData();
  }, []);
  // useEffect(() => {
  //   window.sessionStorage.removeItem("catalogcheckboxes");
  //   window.sessionStorage.removeItem("officecheckboxes");
  //   window.sessionStorage.removeItem("organizationcheckboxes");
  //   window.sessionStorage.removeItem("catalogselect");
  //   window.sessionStorage.removeItem("filterarr");
  //   window.sessionStorage.removeItem("selectedKeyboard");
  //   window.sessionStorage.removeItem("conditiontype");
  //   window.sessionStorage.removeItem("selectedCondition");
  //   window.sessionStorage.removeItem("keyboardtype");
  // }, []);
  return (
    <section className="category">
      <h2 className="category__title">Market</h2>
      <ul className="category__list">
        {data?.map((item, index) => (
          <li
            key={index}
            onClick={() => navigate(`/category/item/${item?.id}`)}
            className={
              data?.length - 0 >= 5
                ? "category__listitem"
                : "category__listitem2"
            }
          >
            {item?.product_count == 0 && (
              <h4 className="noproduct">
                {" "}
                There are no products available now
              </h4>
            )}
            <div
              style={{
                backgroundImage: `url(${item?.image})`,
                marginBottom: "2px",
              }}
              className={
                data?.length - 0 >= 5
                  ? "category__listimg"
                  : "category__listimg2"
              }
            ></div>
            <h4 className="category__listname">{item?.name}</h4>
            <h5 className="category__listlimit">
              Limit: {item?.limit === "no_limit" ? "No Limit" : item?.limit}
            </h5>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Category;
