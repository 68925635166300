import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../../components/Preloader/Preloader";
import http from "../../service/axios";
import axios from "axios";
import { useCookies } from "react-cookie";
const Loading = () => {
  const [cookies, setCookie] = useCookies(["token"]);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  useEffect(() => {
    if (code) {
      http
        .post("accounts/okta/login/", {
          code: code,
        })
        .then((res) => {
          // console.log("cookie", res.headers["auth_token"]);
          setCookie("token", res.data.token, {
            path: "/",
          });

          navigate("/");
          window.location.reload();
        })

        .catch((err) => {
          console.log(err);
          navigate("/login");
        });
    } else {
      // navigate("/");
    }
  }, []);

  return (
    <div>
      <Preloader />
    </div>
  );
};

export default Loading;
