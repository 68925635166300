import React, { useEffect, useState } from "react";
import "./rules.css";
import http from "../../service/axios";
import Preloader from "../../components/Preloader/Preloader";
const Rules = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const getData = () => {
    setLoading(true);
    http
      .get("/api/v1/rules/")
      .then((res) => {
        setData(res.data?.at(0));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <section className="rules">
      {loading && <Preloader />}
      <div className="rules__wrapper">
        <h2 className="rules__title">Rules</h2>
        <div
          dangerouslySetInnerHTML={{ __html: data?.description }}
          className="rules__content"
        ></div>
      </div>
    </section>
  );
};

export default Rules;
